/* eslint-disable */

import axios from 'axios';
import { showAlert } from './alerts';

export const login = async (email, password) => {
  // console.log('Login', email, password);

  try {
    const result = await axios({
      method: 'POST',
      url: '/api/v1/users/login', // only works if API and website are on the same server
      // e.g. a shared url
      // url: 'http://127.0.0.1:3000/api/v1/users/login', // development
      data: {
        email, // remember this is really email: email
        // the name of field in the API and the parameter name
        // if they are the same the shorthand can be used
        password,
      },
    });

    if (result.data.status === 'success') {
      // alert('Logged in successfully'); // this is the standard js alert
      showAlert('success', 'Logged in successfully'); // our custom alert

      // now reload the main page after a wait of 1.5 seconds
      // we supply the route of the the page we want '/'
      // and a delay in millisecs

      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }

    // console.log(result);
  } catch (err) {
    // showAlert('error', err.response.data.message); // login failed
    showAlert('error', 'Login Failed'); // login failed
  }
};

export const logout = async () => {
  try {
    const result = await axios({
      method: 'GET',
      url: '/api/v1/users/logout', // this only works if API and Website are on the same server
      // url: 'http://127.0.0.1:3000/api/v1/users/logout',  // development
    });

    // Force a page reload which sends the token-less cookie to the back-end
    // which completes the log out.
    // The 'true' parameter forces a new request so the page is not taken from
    // a local cache
    if (result.data.status === 'success') location.reload(true);
  } catch (err) {
    showAlert('error', 'Logout Failed - try again'); // login failed
  }
};
