/* eslint-disable */

import axios from 'axios';
import { showAlert } from './alerts';

// initialise the front-end stripe with our public key
const stripe = Stripe(
  'pk_test_51HlXfVFIXZx0wfXXGbieJrN0lYbiwAh3HuIR7xrv3WBI0uCwZt25cZHsLInN3VaPwIKP1OcEEW5tZ4yRgzRb1kPo00irNF9oIU'
);

export const bookPainting = async (paintingId) => {
  try {
    // 1) get checkout-session from API

    const session = await axios({
      method: 'GET',
      url: `/api/v1/bookings/checkout-session/${paintingId}`, // works only if API and website share url
      // url: `http://127.0.0.1:3000/api/v1/bookings/checkout-session/${paintingId}`, // development
    });

    // console.log(session);

    // 2) create checkout form & charge credit card

    await stripe.redirectToCheckout({
      sessionId: session.data.session.id,
    });
  } catch (err) {
    console.log(err);
    showAlert('error', err);
  }
};
