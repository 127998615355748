/* eslint-disable */

import axios from 'axios';
import { showAlert } from './alerts';



export const makeUser = async (
  name,
  email,
  role,
  password,
  passwordConfirm
) => {
  console.log('makeUser', name, email, role, password, passwordConfirm);

  try {
    const result = await axios({
      method: 'POST',
      url: `/api/v1/users/make`, // only works if API and website are on the same server
      data: {
        name,
        email,
        role,
        password,
        passwordConfirm,
      },
    });

    if (result.data.status === 'success') {
      showAlert('success', 'Make User successful'); // our custom alert

      // now reload the 'my users' page after a wait of 1.5 seconds
      // we supply the route of the the page we want '/'
      // and a delay in millisecs

      window.setTimeout(() => {
        location.assign('/my_users');
      }, 1500);
    }

    // console.log(result);
  } catch (err) {
    // showAlert('error', err.response.data.message);
    showAlert('error', 'Make User failed');
  }
};

export const makePainting = async (data) => {
  console.log('makePainting', data);

  try {
    const result = await axios({
      method: 'POST',
      url: `/api/v1/paintings/make`, // only works if API and website are on the same server
      data,
    });

    if (result.data.status === 'success') {
      showAlert('success', 'Make Painting successful'); // our custom alert

      // now reload the 'my paintings' page after a wait of 1.5 seconds
      // we supply the route of the the page we want '/'
      // and a delay in millisecs

      window.setTimeout(() => {
        location.assign('/my_paintings');
      }, 1500);
    }

    // console.log(result);
  } catch (err) {
    // showAlert('error', err.response.data.message);
    showAlert('error', 'Make Painting failed');
  }
};


