/* eslint-disable */

import axios from 'axios';
import { showAlert } from './alerts';

export const signup = async (name, email, password, passwordConfirm) => {
  console.log('SignUp', name, email, password, passwordConfirm);

  try {
    const result = await axios({
      method: 'POST',
      url: '/api/v1/users/signup', // only works if API and website are on the same server
      // e.g. a shared url
      // url: 'http://127.0.0.1:3000/api/v1/users/login', // development
      data: {
        name,
        email, // remember this is really email: email
        // the name of field in the API and the parameter name
        // if they are the same the shorthand can be used
        password,
        passwordConfirm,
      },
    });

    if (result.data.status === 'success') {
      // alert('Signed up successfully'); // this is the standard js alert
      showAlert('success', 'Signed Up successfully'); // our custom alert

      // now reload the main page after a wait of 1.5 seconds
      // we supply the route of the the page we want '/'
      // and a delay in millisecs

      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }

    // console.log(result);
  } catch (err) {
    // showAlert('error', err.response.data.message); // login failed
    showAlert('error', 'Sign Up Failed'); // login failed
  }
};
