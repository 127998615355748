/* eslint-disable */

import '@babel/polyfill';
import { displayMap } from './mapbox';
import { login, logout } from './login';
import { signup } from './signup';
import { bookPainting } from './stripe';
import { updateUser, deleteUser, updateUserByAdmin } from './updateUser';
import { updatePainting, deletePainting } from './updatePainting';
import { makeUser, makePainting } from './makeEntries'; // or Admins

// DOM elements

const mapBox = document.getElementById('map');
const loginForm = document.querySelector('.form--login');
const logOutBtn = document.querySelector('.nav__el--logout');
const userDataForm = document.querySelector('.form-user-data');
const userPasswordForm = document.querySelector('.form-user-password');

const bookBtn = document.getElementById('book-painting');
const signUpForm = document.querySelector('.form--signup');

const userUpdateBtn = document.getElementById('user-update');
const paintingUpdateBtn = document.getElementById('painting-update');

const userDeleteBtn = document.getElementById('user-delete');
const paintingDeleteBtn = document.getElementById('painting-delete');

const makeUserForm = document.querySelector('.form-make-user');
const makePaintingForm = document.querySelector('.form-make-painting');

// Delegations

// retrieving the data from the backend and turning back into json
if (mapBox) {
  const location = JSON.parse(mapBox.dataset.location);
  // console.log(locations);

  displayMap(location);
}

// set a listener for the submit event on our login form

if (loginForm) {
  loginForm.addEventListener('submit', (e) => {
    e.preventDefault(); // stops another page loading - wtf!
    // retrieve the input values from the defined fields
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    // console.log('Listener', email, password);

    login(email, password);
  });
}

if (logOutBtn) logOutBtn.addEventListener('click', logout);

// set a listener for the submit event on user form

if (userDataForm) {
  userDataForm.addEventListener('submit', async (e) => {
    e.preventDefault(); // stops another page loading - wtf!

    document.querySelector('.btn--green').textContent = 'Updating...';

    const form = new FormData();
    // retrieve the input values from the defined fields
    // and add to the form
    form.append('name', document.getElementById('name').value);
    form.append('email', document.getElementById('email').value);
    form.append('photo', document.getElementById('photo').files[0]);
    // the file(s) are actually an array so our 1 file is the 1st
    // console.log(form);

    // Display the values
    // for (var value of form.values()) {
    //   console.log(value);
    // }

    await updateUser(form, 'data');

    // this stuff was added so the page refreshes with the new data showing
    // also the aync/await - all necessary if the photo is updated
    document.querySelector('.btn--green').textContent = 'Save settings';

    location.reload();
  });
}

if (userPasswordForm) {
  userPasswordForm.addEventListener('submit', async (e) => {
    e.preventDefault(); // stops another page loading - wtf!

    // update button label to show update is in progress
    // password updates can take some time for the encryption
    // TODO not sure why this stopped working
    // document.querySelector('btn--save-password').textContent = 'Updating...';

    // retrieve the input values from the defined fields
    const passwordCurrent = document.getElementById('password-current').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;

    // console.log('PWD Listener', passwordCurrent, password, passwordConfirm);

    // we need to wait for this to finish
    await updateUser(
      {
        passwordCurrent,
        password,
        passwordConfirm,
      },
      'password'
    );

    // so we can reset the password fields to blank
    // mustn't leave hanging around
    document.getElementById('password-current').value = '';
    document.getElementById('password').value = '';
    document.getElementById('password-confirm').value = '';

    // update button label to show update has finished
    // TODO not sure why this stopped working
    // document.querySelector('btn--save-password').textContent = 'Save password';
  });
}

// set a listener for the submit event on our signup form

if (signUpForm) {
  signUpForm.addEventListener('submit', (e) => {
    e.preventDefault(); // stops another page loading - wtf!
    // retrieve the input values from the defined fields

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;

    // console.log('Listener Signup', name, email, password, passwordConfirm);

    signup(name, email, password, passwordConfirm);
  });
}

// set a listener for the update btn event on our user form

if (userUpdateBtn) {
  userUpdateBtn.addEventListener('click', async (e) => {
    e.preventDefault(); // stops another page loading - wtf!

    document.querySelector('.btn--green').textContent = 'Updating...';

    const { userId } = e.target.dataset;

    // retrieve the input values from the defined fields
    // and add to the form
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const photo = document.getElementById('photo').files[0];
    // the file(s) are actually an array so our 1 file is the 1st

    const active = document.getElementById('isActive').checked; // retrieve value of a checkbox

    const roles = document.querySelectorAll('input[name="role"]');

    let role;

    for (const r of roles) {
      // retrieve value of a set of radio buttons
      if (r.checked) {
        role = r.value;
        break;
      }
    }

    // console.log(
    //   'Listener Update User',
    //   userId,
    //   name,
    //   email,
    //   active,
    //   role,
    //   photo
    // );

    // updateUser wont work as that code relies on it being the user doing this NOT admin
    await updateUserByAdmin(
      userId,
      {
        name,
        email,
        active,
        role,
        photo,
      },
      'data'
    );

    // this stuff was added so the page refreshes with the new data showing
    // also the aync/await - all necessary if the photo is updated
    document.querySelector('.btn--green').textContent = 'Update User';

    // location.reload();
  });
}

// set a listener for the update btn event on our painting form

if (paintingUpdateBtn) {
  paintingUpdateBtn.addEventListener('click', async (e) => {
    e.preventDefault(); // stops another page loading - wtf!

    console.log('UPDATE PAINTING FORM');

    document.querySelector('.btn--green').textContent = 'Updating...';

    const { paintingId } = e.target.dataset;
    // FORM VERSION
    // const form = new FormData();
    // // retrieve the input values from the defined fields
    // // and add to the form
    // form.append('name', document.getElementById('name').value);
    // form.append('desciption', document.getElementById('description').value);
    // form.append('material', document.getElementById('material').value);
    // form.append('price', document.getElementById('price').value * 1);
    // const sold = document.getElementById('isSold').checked; // retrieve value of a checkbox
    // form.append('sold', sold);
    // const size = [];
    // size[0] = document.getElementById('width').value * 1;
    // size[1] = document.getElementById('height').value * 1;
    // form.append('size', size);
    // const framed = document.getElementById('isFramed').checked; // retrieve value of a checkbox
    // form.append('framed', framed);
    // form.append(
    //   'frame_description',
    //   document.getElementById('frame_description').value
    // );
    // const known = document.getElementById('hasLocation').checked; // retrieve value of a checkbox
    // const coordinates = [];
    // coordinates[0] = document.getElementById('longitude').value * 1;
    // coordinates[1] = document.getElementById('latitude').value * 1;
    // const location = {
    //   known: known,
    //   type: 'Point',
    //   description: document.getElementById('location_description').value,
    //   coordinates: coordinates,
    // };
    // form.append('location', location);
    // const category = document.querySelector('#category').value;
    // form.append('category', category);
    // const photo = document.getElementById('photo').files[0];
    // if (photo) form.append('photo', photo);

    // // the file(s) are actually an array so our 1 file is the 1st
    // // Display the values
    // for (var value of form.values()) {
    //   console.log(value);
    // }

    // OBJECT VERSION
    // retrieve the input values from the defined fields
    const name = document.getElementById('name').value;
    const description = document.getElementById('description').value;
    const material = document.getElementById('material').value;
    const year = document.getElementById('year').value * 1;
    const price = document.getElementById('price').value * 1;
    const sold = document.getElementById('isSold').checked; // retrieve value of a checkbox
    const size = [];
    size[0] = document.getElementById('width').value * 1;
    size[1] = document.getElementById('height').value * 1;
    const framed = document.getElementById('isFramed').checked; // retrieve value of a checkbox
    const frame_description = document.getElementById('frame_description')
      .value;
    const known = document.getElementById('hasLocation').checked; // retrieve value of a checkbox
    const coordinates = [];
    coordinates[0] = document.getElementById('longitude').value * 1;
    coordinates[1] = document.getElementById('latitude').value * 1;
    const location = {
      known: known,
      type: 'Point',
      description: document.getElementById('location_description').value,
      coordinates: coordinates,
    };
    const category = document.querySelector('#category').value;
    const photo = document.getElementById('photo').files[0];

    console.log({
      name,
      description,
      material,
      year,
      price,
      sold,
      size,
      framed,
      frame_description,
      location,
      category,
      photo,
    });

    if (photo) {
      await updatePainting(paintingId, {
        name,
        description,
        material,
        year,
        price,
        sold,
        size,
        framed,
        frame_description,
        location,
        category,
        photo,
      });
    } else {
      await updatePainting(paintingId, {
        name,
        description,
        material,
        year,
        price,
        sold,
        size,
        framed,
        frame_description,
        location,
        category,
      });
    }

    // this stuff was added so the page refreshes with the new data showing
    // also the aync/await - all necessary if the photo is updated
    document.querySelector('.btn--green').textContent = 'Update Painting';

    //    location.reload();
  });
}

// set a listener for the delete btn event on our user form

if (userDeleteBtn) {
  userDeleteBtn.addEventListener('click', (e) => {
    e.preventDefault(); // stops another page loading - wtf!

    const { userId } = e.target.dataset;

    // console.log('Listener Delete User', userId);

    deleteUser(userId);
  });
}

// set a listener for the delete btn event on our painting form

if (paintingDeleteBtn) {
  paintingDeleteBtn.addEventListener('click', (e) => {
    e.preventDefault(); // stops another page loading - wtf!

    const { paintingId } = e.target.dataset;

    // console.log('Listener Delete Painting', paintingId);

    deletePainting(paintingId);
  });
}

// set a listener for the submit event on our Admin make user form

if (makeUserForm) {
  makeUserForm.addEventListener('submit', (e) => {
    e.preventDefault(); // stops another page loading - wtf!
    // retrieve the input values from the defined fields

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;

    const role = document.querySelector('#role').value;

    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;

    console.log(
      'Listener Make User',
      name,
      email,
      role,
      password,
      passwordConfirm
    );

    makeUser(name, email, role, password, passwordConfirm);
  });
}

// set a listener for the submit event on our Admin make painting form

if (makePaintingForm) {
  makePaintingForm.addEventListener('submit', (e) => {
    e.preventDefault(); // stops another page loading - wtf!
    // retrieve the input values from the defined fields

    console.log('MAKE PAINTING FORM');

    const form = new FormData();
    // retrieve the input values from the defined fields
    // and add to the form
    form.append('name', document.getElementById('name').value);
    form.append('description', document.getElementById('description').value);
    form.append('material', document.getElementById('material').value);
    form.append('price', document.getElementById('price').value * 1);

    const size = [];
    size[0] = document.querySelector('width').value * 1;
    size[1] = document.querySelector('height').value * 1;
    form.append('size', size);

    const category = document.querySelector('#category').value;
    form.append('category', category);

    form.append('photo', document.getElementById('photo').files[0]);

    // Display the values
    for (const value of form.values()) {
      console.log(value);
    }

    makePainting(form);
  });
}
