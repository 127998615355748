/* eslint-disable */

import axios from 'axios';
import { showAlert } from './alerts';

export const updatePainting = async (paintingId, data) => {
  console.log('updatePainting', paintingId, data);

  try {
    const result = await axios({
      method: 'PATCH',
      url: `/api/v1/paintings/${paintingId}`, // only works if API and website are on the same server
      data,
    });

    if (result.data.status === 'success') {
      showAlert('success', 'Painting updated successfully'); // our custom alert

      // now reload the 'my paintings' page after a wait of 1.5 seconds
      // we supply the route of the the page we want '/'
      // and a delay in millisecs

      // window.setTimeout(() => {
      //   location.assign('/my_paintings');
      // }, 1500);
    }

    // console.log(result);
  } catch (err) {
    // showAlert('error', err.response.data.message);
    showAlert('error', 'Painting update failed');
  }
};

export const deletePainting = async (paintingId) => {
  // console.log('deletePainting', paintingId);

  try {
    const result = await axios({
      method: 'DELETE',
      url: `/api/v1/paintings/${paintingId}`, // only works if API and website are on the same server
      data: null,
    });

    // console.log(result.data.status);

    showAlert('success', 'Painting deleted successfully'); // our custom alert

    // now reload the 'my paintings' page after a wait of 1.5 seconds
    // we supply the route of the the page we want '/'
    // and a delay in millisecs

    window.setTimeout(() => {
      location.assign('/my_paintings');
    }, 1500);
  } catch (err) {
    // showAlert('error', err.response.data.message);
    showAlert('error', 'Painting delete failed');
  }
};
