/* eslint-disable */

import axios from 'axios';
import { showAlert } from './alerts';

// type = 'data' or 'password'
// data = object of updated fields
export const updateUser = async (data, type) => {
  // console.log(`Update ${type}`, data);

  const url =
    type === 'data'
      ? '/api/v1/users/updateMe' // only works if API and website share a url
      : '/api/v1/users/updateMyPassword/';
  try {
    const result = await axios({
      method: 'PATCH',
      url,
      data,
    });

    if (result.data.status === 'success') {
      showAlert('success', `${type.toUpperCase()} updated successfully`);
    }
  } catch (err) {
    showAlert('error', `${type.toUpperCase()} update failed - try later`);
  }
};

// type = 'data' or 'password'
// data = object of updated fields
export const updateUserByAdmin = async (userId, data, type) => {
  // console.log(
  //   'UPDATEUSERBYADMIN Update User',
  //   userId,
  //   data.name,
  //   data.email,
  //   data.active,
  //   data.role,
  //   type
  // );

  try {
    const result = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${userId}`, // only works if API and website share a url,
      data,
    });

    if (result.data.status === 'success') {
      showAlert('success', `${type.toUpperCase()} updated successfully`);
    }

    // now reload the 'my users' page after a wait of 1.5 seconds
    // we supply the route of the the page we want '/'
    // and a delay in millisecs

    window.setTimeout(() => {
      location.assign('/my_users');
    }, 1500);
  } catch (err) {
    showAlert('error', `${type.toUpperCase()} update failed - try later`);
  }
};

export const deleteUser = async (userId) => {
  // console.log('deleteUser', userId);

  try {
    const result = await axios({
      method: 'DELETE',
      url: `/api/v1/users/${userId}`, // only works if API and website are on the same server
      data: null,
    });

    showAlert('success', 'User deleted successfully'); // our custom alert

    // now reload the 'my users' page after a wait of 1.5 seconds
    // we supply the route of the the page we want '/'
    // and a delay in millisecs

    window.setTimeout(() => {
      location.assign('/my_users');
    }, 1500);
  } catch (err) {
    // showAlert('error', err.response.data.message);
    showAlert('error', 'User delete failed');
  }
};

export const logout = async () => {
  try {
    const result = await axios({
      method: 'GET',
      url: 'http://127.0.0.1:3000/api/v1/users/logout',
    });

    // Force a page reload which sends the token-less cookie to the back-end
    // which completes the log out.
    // The 'true' parameter forces a new request so the page is not taken from
    // a local cache
    if (result.data.status === 'success') location.reload(true);
  } catch (err) {
    showAlert('error', 'Logout Failed - try again'); // login failed
  }
};
