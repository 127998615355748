/* eslint-disable */

export const hideAlert = () => {
  const el = document.querySelector('.alert'); // find the alert

  // and delete it - remember you need to specify a parent to remove the child
  if (el) el.parentElement.removeChild(el);
};

// type is either 'success' or 'error'
export const showAlert = (type, message) => {
  // hide any alerts already showing (just to be safe)
  hideAlert();

  const markup = `<div class="alert alert--${type}">${message}</div>`;

  // insert this markup into our html
  document.querySelector('body').insertAdjacentHTML('afterbegin', markup);

  // wait 5 seconds and then hide the alert
  window.setTimeout(hideAlert, 5000);
};
