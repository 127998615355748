/* eslint-disable */
// eslint is set for node.js and this is javascript

// import { RuleTester } from 'eslint';

export const displayMap = (location) => {
  mapboxgl.accessToken =
    'pk.eyJ1Ijoic3RldmVuaG91c2UiLCJhIjoiY2toMmgyczJrMDIwcjJ5bmw5b2xjbW1xdyJ9.gHQOUfsklE11_7CbfUER1A';

  var map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/stevenhouse/ciy5t955a004p2sofc8zx9fan',
    //  scrollZoom: false, // disables zooming on the map
    //   TODO add a toggle button to allow zoom scrolling
    center: [2.23241, 50.28967], // 2 Petites Rues, Boubers-sur-Canche, France 62270 Simon Korving Studio
    //    zoom: 1,
  });

  const bounds = new mapboxgl.LngLatBounds();

  // create marker
  const el = document.createElement('div'); // create a div in the html
  el.className = 'marker'; // with this class - which relates to a marker graphic

  // add marker
  new mapboxgl.Marker({
    element: el, // add this marker to the map
    anchor: 'bottom', // and attach the bottom of the graphic to the lng/lat
  })
    .setLngLat(location.coordinates) // and set the lng/lat from the array coordinates field
    .addTo(map); // and add these to our map

  // add popup for locations
  new mapboxgl.Popup({
    offset: 30,
  })
    .setLngLat(location.coordinates)
    .setHTML(`<p>At ${location.description}</p>`)
    .addTo(map);
  // add to bounds
  bounds.extend(location.coordinates); // and then add them to the bounds

  // display map with bounds
  map.fitBounds(bounds, {
    padding: {
      top: 200,
      bottom: 100,
      left: 100,
      right: 100,
    },
    maxZoom: 12,
  }); // and finally make the map fit the bounds
};
